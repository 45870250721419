import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

interface BannerHighlightedProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const BannerHighlighted: React.FC<BannerHighlightedProps> = ({ fields }) => (
  <section className="mb-lg-5">
    <Blog fields={fields} />
  </section>
)

interface BlogProps {
  fields: any
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedProjectQueryQuery>(graphql`
    query highlightedProjectQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            databaseId
            title
            uri
            projectdetail {
              mainimage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 1400)
                  }
                }
              }
              description
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProject.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="highlighted-Project"
      showIds={fields.relation.map(({ databaseId }: any) => databaseId)}
      limit={Infinity}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: any
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <BlogGridPost
      // @ts-ignore
      node={blogBink.posts[0].node}
      bgColor={fields.backgroundcolor}
    />
  )
}

const Description = styled.div<{ bgColor: string }>`
  min-height: 509px;
  & h1 {
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  & h6 {
    color: ${(props) =>
      props.bgColor === 'dark'
        ? props.theme.color.background
        : props.theme.color.light};
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  overflow: hidden !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  & p {
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`

const Image = styled(Plaatjie)`
  height: 100%;
  width: 100%;

  & img {
    object-fit: cover !important;
  }
`

const BannerHighlightedBackground = styled.div<{ bgColor: string }>`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;

  & > .container {
    background-color: ${(props) =>
      props.bgColor === 'dark'
        ? props.theme.color.primary
        : props.theme.color.background};

    @media (min-width: 1400px) {
      max-width: 1400px;
    }

    @media (min-width: 1650px) {
      max-width: 1600px;
    }

    @media (max-width: 1399px) {
      max-width: 100%;
    }
  }
  @media (max-width: 767px) {
    max-height: 450px;
  }
`

const Overlay = styled.div<{ bgColor: string }>`
  opacity: 0.75;
  background-color: ${(props) =>
    props.bgColor === 'dark'
      ? props.theme.color.primary
      : props.theme.color.background};
  top: 0;
  left: 0;
  z-index: 9;
`

const BannerHighlightedContent = styled.div`
  position: relative;
  z-index: 2;
`

const Wrapper = styled.div<{ bgColor: string }>`
  @media (min-width: 992px) {
    background-color: ${(props) =>
      props.bgColor === 'dark'
        ? props.theme.color.primary
        : props.theme.color.background};
  }
`

const Container = styled.div`
  @media (max-width: 1440px) {
    margin-right: 0 !important;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  node: {
    id: string
    title: string
    uri: string
    // eslint-disable-next-line
    projectdetail: GatsbyTypes.WpProject_Projectdetail
  }
  bgColor: string
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, bgColor }) => (
  <Wrapper className="position-relative" bgColor={bgColor}>
    <BannerHighlightedBackground bgColor={bgColor}>
      <Overlay
        className="w-100 h-100 d-block d-lg-none position-absolute"
        bgColor={bgColor}
      />
      <Container className="container h-100">
        <div className="row h-100 justify-content-end">
          <div className="col-lg-6 px-0">
            <Image image={node.projectdetail.mainimage} alt={node.title} />
          </div>
        </div>
      </Container>
    </BannerHighlightedBackground>
    <BannerHighlightedContent className="container">
      <div className="row">
        <Description
          className="col-lg-6 d-flex align-items-center py-5"
          bgColor={bgColor}
        >
          <div className="py-4 pe-lg-5">
            <div className="mb-4">
              <h6>UITGELICHT PROJECT</h6>
            </div>
            <h1 className="text-uppercase pb-3">{node.title}</h1>
            <Content
              content={node.projectdetail.description}
              className="pe-lg-5"
            />
            <div className="mt-5">
              <ButtonPrimary to={node.uri}>BEKIJK PROJECT</ButtonPrimary>
            </div>
          </div>
        </Description>
      </div>
    </BannerHighlightedContent>
  </Wrapper>
)

export default BannerHighlighted
