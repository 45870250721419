import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerHome = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  /* max-width: 2000px; */
  @media (min-width: 991px) {
    height: 80vh;
  }
  @media (max-width: 992px) {
    min-height: 350px;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  & img {
    object-fit: cover !important;
    object-position: 0%;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.ultra};
    line-height: ${({ theme }) => theme.font.size.giga};
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.8);
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.ultra};
      line-height: ${({ theme }) => theme.font.size.giga};
    }
    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => (
  <StyledBannerHome className="mb-5">
    <BannerWrapper className="mx-auto">
      <StyledPlaatjie alt="banner" image={fields?.image} />
      <div
        className="container py-5 d-flex align-items-center h-100"
        style={{ minHeight: 336 }}
      >
        <Content
          className="w-100 mb-lg-5 pb-lg-5"
          content={fields.description}
        />
      </div>
    </BannerWrapper>
  </StyledBannerHome>
)

export default BannerHome
