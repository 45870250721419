import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerDefault = styled.section`
  background-color: ${({ theme }) => theme.color.primary};
`

const BannerWrapper = styled.div`
  position: relative;
  max-width: 1600px;
  z-index: 1;
  @media (min-width: 991px) {
    height: 509px;
  }
  @media (max-width: 992px) {
    min-height: 250px;
  }
`

const Image = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.ultra};
    line-height: ${({ theme }) => theme.font.size.giga};
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.8);
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault className="mb-5">
    <BannerWrapper className="mx-auto">
      <Image alt="banner" image={fields?.image} />
      <div className="container py-5 d-flex align-items-center h-100">
        <Content className="w-100 mb-5 pb-5" content={fields.description} />
      </div>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
